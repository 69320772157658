import React, { useState, useRef, useEffect } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { PageLayout } from '../../layout/PageLayout/PageLayout';
import './NewNotification.scss';
import InputField from '../../components/InputField/InputField';
import { Button } from 'primereact/button';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  createNotification,
  getNotification,
  updateNotification
} from '../../services/NotificationService';
import TextareaField from '../../components/TextareaField/TextareaField';

const NewNotification = () => {
  const [totalSize, setTotalSize] = useState(0);
  const [image, setImage] = useState<any>(undefined);
  const [uploadImage, setUploadImage] = useState<any>();
  let serverUrl = process.env.REACT_APP_API_URL;
  let storageUrl = serverUrl + 'files/storage/';
  const toast = useRef<any>(null);
  const fileUploadRef = useRef<any>(null);
  const [data, setData] = useState({
    name: '',
    description: '',
    data: ''
  });

  const navigate = useNavigate();

  let { id } = useParams();

  useEffect(() => {
    if (id !== undefined) {
      getNotification(id)
        .then((response) => {
          const data = response.data.result;
          setUploadImage(storageUrl + response?.data?.result?.file?.id);
          setData({
            name: data.name,
            description: data.description,
            data: data.data
          });
        })
        .catch((err) => console.log(err));
    } else {
      setData({
        name: '',
        description: '',
        data: ''
      });
    }
  }, [id]);

  const handleData = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleCreation = () => {
    if (data.name !== '' && data.description !== '') {
      if (id === undefined) {
        createNotification(data)
          .then((response) =>
            navigate('/notification-list', {
              replace: true,
              state: { toast: toast, message: 'Uspešno kreirano novo obavestenje' }
            })
          )
          .catch((err) => console.log(err));
      } else {
        updateNotification(id, { ...data, name: data.name, description: data.description })
          .then((response) =>
            navigate('/notification-list', {
              replace: true,
              state: { toast: toast, message: 'Uspešno ažurirano obavestenje' }
            })
          )
          .catch((err) => console.log(err));
      }
    }
  };

  return (
    <PageLayout>
      <div className="new-banner">
        <Breadcrumb
          title={
            id === undefined
              ? 'Obavestenja / Nova obavestenja'
              : 'Obavestenja / Lista obavestenja / Uredi obavestenja'
          }
        />
        <div className="new-banners">
          <div className="input-fields">
            <InputField
              inputContainer={'input-container'}
              placeholder={'Unesite naziv obavestenja'}
              value={data.name}
              name={'name'}
              onChange={handleData}
              inputStyle={'inputStyle'}
              label={'Naziv obavestenja'}
            />

            <TextareaField
              inputContainer={'textarea-container'}
              inputStyle={'inputStyle'}
              value={data.description}
              name={'description'}
              onChange={handleData}
              placeholder={'Unesite kratak opis'}
              label={'Kratak opis'}
            />

            <TextareaField
              inputContainer={'textarea-container'}
              inputStyle={'inputStyle'}
              value={data.data}
              name={'data'}
              onChange={handleData}
              placeholder={'Unesite opis'}
              label={'Opis'}
              minHeight={'150px'}
            />

            <div className="inner-button-container">
              {id === undefined ? (
                <button
                  disabled={data.name === '' || data.description === '' ? true : false}
                  onClick={handleCreation}
                  className={data.name === '' || data.description === '' ? 'btn-disabled' : 'btn'}>
                  Sacuvaj
                </button>
              ) : (
                <button
                  disabled={data.name === '' || data.description === '' ? true : false}
                  onClick={handleCreation}
                  className={data.name === '' || data.description === '' ? 'btn-disabled' : 'btn'}>
                  Izmeni
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default NewNotification;
